import React, { useState, useEffect } from "react";
import { Col } from "react-bootstrap";

const Player = ({ url, state }) => {
  return (
    <audio src={url} controls controlsList="nodownload">
      Error: your web browser does not support this audio player.
    </audio>
  );
};

const Audio = ({ url }) => {
  const [state, setState] = useState(false);

  useEffect(() => {
    return () => {
      setState(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  return (
    <React.Fragment>
      <Col className="col-12 mb-3 d-flex justify-content-center ">
        <Player url={url} state={state}/>
      </Col>
    </React.Fragment>
  );
};

export default React.memo(Audio, (prevProps, props) => {
  return prevProps.audioId === props.audioId;
});
