import React, { useEffect, useContext, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Row, Col, Container, Card, Carousel } from "react-bootstrap";
import styled from "styled-components";
import renderHTML from "react-render-html";
import ReactGA from "react-ga";
import ImageLoader from "./../../components/ImageLoader";
import Header from "../../components/Header";
import Audio from "../../components/Audio";
import AudioUrl from "../../components/Audio/url";
import Video from "../../components/Video";
import { checkData } from "../../hoc";
import { AppContext } from "../../AppContext";

import NEXTSVG from "./../../assets/svg/next1.svg";
import NEXTPRESSSVG from "./../../assets/svg/next2.svg";
import BACKSVG from "./../../assets/svg/back1.svg";
import BACKPRESSSVG from "./../../assets/svg/back2.svg";
import GEOSVG from "./../../assets/svg/geolocalizacion.svg";
import GEOPRESSSVG from "./../../assets/svg/geolocalizacion1.svg";
import { getImage } from '../../services/index'

const ContainerWrapper = styled(Container)`
  margin-top: 130px;
  margin-bottom: 30px;
`;

const ImgBackControl = styled.div`
  height: 50px;
  cursor: pointer;

  background-image: url("${BACKSVG}");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  width: 50px;
  height: 50px;
  cursor: pointer;
  &:focus,
  &:hover {
    background-image: url("${BACKPRESSSVG}");
  }
`;

const ImgNextControl = styled.div`
  height: 50px;
  cursor: pointer;

  background-image: url("${NEXTSVG}");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  width: 50px;
  height: 50px;
  cursor: pointer;
  &:focus,
  &:hover {
    background-image: url("${NEXTPRESSSVG}");
  }
`;

const DivSpace = styled.div`
  height: 50px;
  width: 50px;
`;

const CardWrapper = styled(Card)`
  box-shadow: 8px 8px 5px #dfdedb;
  &:focus,
  &:hover {
    box-shadow: 10px 10px 5px #dfdedb;
  }
`;

const GeoImg = styled.img`
  width: 20px;
  height: 30px;
  cursor: pointer;
  margin-left: 15px;
`;

const CardTitle = styled(Card.Title)`
  /* font-family: Armata-Regular; */
  color: #150f15;
  font-size: 1.5em;
`;

const RowTitle = styled(Row)`
  /* font-family: Armata-Regular; */
  color: #150f15;
  font-size: 1.5em;
`;

const CardText = styled(Card.Text)`
  /* font-family: HelveticaNeue-Regular; */
  margin-bottom: 0;
  letter-spacing: 1px;
  font-size: 20px;
  font-weight: bold;
`;

const DescriptionArtWork = styled.span`
  font-size: 18px;
` 

const CardText2 = styled(Card.Text)`
  /* font-family: HelveticaNeue-Regular; */
  margin-bottom: 0;
  letter-spacing: 2px;
  font-size: 16px;
  font-weight: 700;
`;
const useWindowHeight = () => {
  const [height, setHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => setHeight(window.innerHeight);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return height;
};

const Artwork = () => {
  let history = useHistory();
  let { collectionId, artworkId } = useParams();

  const { collections, language, filter, setScrollId } = useContext(AppContext);

  const [image, setImage] = useState(null)
  const [artwork, setArtwork] = useState(null);
  const [showGeo, setShowGeo] = useState(false);
  const [artworks, setArtworks] = useState([]);
  const [height] = useState(useWindowHeight());
  const [ra, setRa] = useState(Math.floor(Math.random() * 11));

  const [controlsCarousel, setControlsCarousel] = useState(false)
  const [indicatorsCarousel, setIndicatorsCarousel] = useState(false)

  useEffect(() => {
    fetchArtwork();
    setScrollId(artworkId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionId, artworkId]);



  const fetchArtwork = async () => {
    if (collectionId) {
      if (filter) {
        filterCollections();
      } else {
        const dataCol = collections.filter((item) => {
          return parseInt(item.id) === parseInt(collectionId);
        });
        if (dataCol.length) {

          dataCol[0].obras.filter(item => {
            if(parseInt(item.id) === parseInt(artworkId)){
              if(item.imagenes.length > 1){
                setControlsCarousel(true)
                setIndicatorsCarousel(true)
              }
            }
          })

          setArtworks(sorts(dataCol[0].obras));
          const data = dataCol[0].obras.filter((item) => {
            return parseInt(item.id) === parseInt(artworkId);
          });
          if (data.length) {
            runGA(data[0]);
            setArtwork(data[0]);
          }
        }
      }
    }
  };

  const filterCollections = () => {
    setArtworks(sorts(filter.obras));
    const art = filter.obras.filter((item) => {

      return parseInt(item.id) === parseInt(artworkId);
    });
    if (art.length) {
      setArtwork(art[0]);
    }
  };

  const sorts = (array) => {
    for (let i = 0; i < array.length - 1; i++) {
      for (let j = i + 1; j < array.length; j++) {
        if (parseInt(array[i].orden) > parseInt(array[j].orden)) {
          let aux = array[i];
          array[i] = array[j];
          array[j] = aux;
        }
      }
    }
    return array;
  };

  const prev = () => {
    for (let i = 0; i < artworks.length; i++) {
      if (artworks[i].id === artwork.id) {
        if (i + 1 < artworks.length) {
          history.push(
            `/collection/${collectionId}/artwork/${artworks[i + 1].id}`
          );
        } else {
          history.push(`/collection/${collectionId}/artwork/${artworks[0].id}`);
        }
      }
    }
  };

  const next = () => {
    for (let i = 0; i < artworks.length; i++) {
      if (artworks[i].id === artwork.id) {
        if (i - 1 >= 0) {
          history.push(
            `/collection/${collectionId}/artwork/${artworks[i - 1].id}`
          );
        } else {
          history.push(
            `/collection/${collectionId}/artwork/${
              artworks[artworks.length - 1].id
            }`
          );
        }
      }
    }
  };

  const runGA = (item) => {
    if (item && language) {
      ReactGA.event({
        category: "Atwork",
        action: `Visited -${item.titulo[language.abreviatura]}`,
        value: parseInt(artworkId),
      });
    }
  };

  const showLocation = () => {
    setShowGeo(!showGeo);
    setRa(Math.floor(Math.random() * 11));
  };

  return (
    <React.Fragment>
      <Header />
      {!artwork && (
        <Row style={{ marginTop: 260 }}>
          <Col className="d-flex justify-content-center"></Col>
        </Row>
      )}

      {artwork && artwork.titulo[language.abreviatura] && (
          <React.Fragment>
            <Row
            className="sg-container d-flex justify-content-center no-gutters"
            style={{ marginTop: 125 }}
          >
            <Col className="m-2 col-12 d-flex justify-content-center">
              <Container className="w-100">

                {(!artwork.es_sala || artwork.es_sala === "0") &&
                  artwork.imagen && (
                    <React.Fragment key={`key-card-${ra}`}>
                      {artwork.ubicacion &&
                        artwork.ubicacion[language.abreviatura] && (
                          <ImageLoader
                            hide={!showGeo}
                            key={`image-${artwork.imagen_id}`}
                            id={artwork.ubicacion[language.abreviatura]}
                            url={null}
                            obra={artwork.id}
                            height={height}
                          />
                        )}

                    </React.Fragment>
                  )
                }

                <Card.Body>
                  <Row className="justify-content-center">
                    <Col>
                      {
                        <Carousel
                          controls={controlsCarousel}
                          indicators={indicatorsCarousel}
                        >
                          {artwork.imagenes.map((img, i) => (
                            <Carousel.Item>
                              <ImageLoader
                                key={`image-${img}`}
                                id={img}
                                obra={img}
                              />
                            </Carousel.Item>
                          ))}
                        </Carousel>
                      }
                    </Col>
                  </Row>

                  <CardText>

                      {artwork.audioguia && (
                        <React.Fragment>
                          {language.AUDIOGUIA} {artwork.audioguia}
                        </React.Fragment>
                      )}
                      &nbsp;
                    </CardText>

                    <Row>
                      <RowTitle className="d-flex justify-content-between">
                        <Col className="col-12">
                          <span className="h3">
                            {artwork.titulo[language && language.abreviatura]}
                          </span>
                        </Col>

                        <Col className="col-12 my-4">
                          <Row className="align-items-center">
                            <Col className="col-2 d-flex justify-content start">
                              <ImgBackControl onClick={next} />
                            </Col>
                            <Col className="col-8 text-center">
                              {Object.keys(artwork.audio).length > 0 &&
                              artwork.audio[language.abreviatura] && (
                                <Row className="d-flex justify-content-center">
                                  <Audio
                                    audioId={artwork.audio[language.abreviatura]}
                                    name={artwork.titulo[language.abreviatura]}
                                  />
                                </Row>
                              )}
                            </Col>
                            <Col className="col-2 text-center d-flex justify-content start">
                              <ImgNextControl onClick={prev} />
                            </Col>
                          </Row>
                        </Col>

                        <Col xs={12} className="my-0 d-flex justify-content-center">
                          {Object.keys(artwork.video).length > 0 &&
                          artwork.video[language.abreviatura].length > 0 &&
                          artwork.video[language.abreviatura] &&
                          (
                            <Video
                              videoId={artwork.video[language.abreviatura]}
                              name={artwork.titulo[language.abreviatura]}
                            />
                          )}
                        </Col>

                  
                        <Col className="col-12 mt-4 contenido">
                          <DescriptionArtWork>
                            {renderHTML(
                              artwork.contenido[
                                language && language.abreviatura
                              ]
                            )}
                          </DescriptionArtWork>
                        </Col>

                        {artwork.ubicacion &&
                          artwork.ubicacion[language.abreviatura] && (
                            <GeoImg
                              onClick={showLocation}
                              src={!showGeo ? GEOSVG : GEOPRESSSVG}
                              alt=""
                            />
                        )}
                      </RowTitle>
                    </Row>
                    
                    {
                      artwork.videoUrl &&
                      artwork.videoUrl[language.abreviatura] &&
                      (
                        Object.keys(artwork.videoUrl) &&(
                          <React.Fragment>
                            {artwork.videoUrl[language.abreviatura].map(
                            (item) => (
                              <Video
                              // videoId={artwork.video[language.abreviatura]}
                                name={artwork.titulo[language.abreviatura]}
                                url={item}
                              />
                            )
                            )}
                          </React.Fragment>
                        )
                      )
                    }

                </Card.Body>
              </Container>
            
            </Col>
          </Row>
          </React.Fragment>
      )}


    </React.Fragment>
  );
};

export default checkData(Artwork);