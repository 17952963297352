import axios from "axios";
import { reactLocalStorage } from "reactjs-localstorage";
// const HOST_API = "http://localhost/admin/public/api";
const HOST_API = "https://f87c17b8-6fbe-4d85-95d8-2f41badfcd6f.clouding.host/almagro/admin/public/api";



const password = "secret";

export const login = () => {
  return axios({
    method: "post",
    url: `${HOST_API}/code/login`,
    data: {
      password,
    },
  });
};

export const sendQuestionnaire = (questionary) => {
  const token = reactLocalStorage.get("token", false);
  return axios({
    method: "post",
    url: `${HOST_API}/cuestionario/store`,
    data: {
      cuestionario: questionary,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getCollections = (idioma_id) => {
  const token = reactLocalStorage.get("token", false);
  return axios({
    method: "get",
    url: `${HOST_API}/colecciones`,
    params: {
      idioma_id,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getAllData = (code = null) => {
  const token = reactLocalStorage.get("token", false);
  return axios({
    method: "get",
    url: `${HOST_API}/todo`,
    params: { code },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getLanguages = () => {
  const token = reactLocalStorage.get("token", false);
  return axios({
    method: "get",
    url: `${HOST_API}/idiomas`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getCollection = (id, idioma_id) => {
  const token = reactLocalStorage.get("token", false);

  return axios({
    method: "get",
    url: `${HOST_API}/colecciones/${id}`,
    params: { idioma_id },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getImage = (id) => {
  const token = reactLocalStorage.get("token", false);
  return axios({
    method: "get",
    url: `${HOST_API}/file/download/${id}`,
    responseType: "blob", // important
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getFile = (id) => {
  const token = reactLocalStorage.get("token", false);
  return axios({
    method: "get",
    url: `${HOST_API}/file/download/${id}`,
    responseType: "blob", // important
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getFileTest = () => {
  // const token = reactLocalStorage.get("token", false);
  return axios({
    method: "get",
    url: `http://localhost:3000/audios/1/es/1.mp3`,
    responseType: "blob", // important
    headers: {
      // Authorization: `Bearer ${token}`,
    },
  });
};
export const getImageTest = () => {
  // const token = reactLocalStorage.get("token", false);
  return axios({
    method: "get",
    url: `http://localhost:3000/audios/home.png`,
    responseType: "blob", // important
    headers: {
      // Authorization: `Bearer ${token}`,
    },
  });
};
