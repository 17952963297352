import React from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Navbar } from "react-bootstrap";
import styled from "styled-components";

import LOGO from "./../../assets/logo1.png";

import BACK from "./../../assets/svg/flecha_back1.svg";
import BACKPRESS from "./../../assets/svg/flecha_back2.svg";

import "./index.css";

const NavbarWrapper = styled(Navbar)`
  height: 60px;
  background-color: #403832;
  box-shadow: 0 2px 8px #f0f1f2;
  text-align: center;
  cursor: pointer;
  font-family: EBGaramond-Variable;
  span {
    color: #c2bcb7;
    font-size: 18px;
  }

  @media(max-width: 600px){
    span{
      font-size: 13px;
    }
  }
`;
const Image = styled.img`
  width: 70px;
  height: 70px;
  margin-top: 10px;
`;
const DivBack = styled.div`
  position: absolute;
  left: 37px;
  top: 22px;

  height: 50px;
  cursor: pointer;
  background-image: url("${BACK}");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  width: 20px;
  height: 20px;
  cursor: pointer;
  &:focus,
  &:hover {
    background-image: url("${BACKPRESS}");
  }
`;

const Header = () => {
  let history = useHistory();
  let location = useLocation();
  let { collectionId } = useParams();

  const goToHome = () => {
    history.push("/languages");
  };

  const goBack = () => {
    history.push(`/collection/${collectionId}`);
  };

  const hideMenu = () => {
    return (
      location.pathname.includes("collection") &&
      location.pathname.includes("artwork")
    );
  };

  return (
    <React.Fragment>
      <NavbarWrapper fixed="top" className="d-flex justify-content-center">
        <Navbar.Brand>
          {/* <Image onClick={goToHome} src={LOGO} alt="Logo App" /> */}
          <span onClick={goToHome}>IGLESIA DE SAN AGUSTÍN - ALMAGRO</span>
          {hideMenu() && <DivBack onClick={goBack} />}
        </Navbar.Brand>
      </NavbarWrapper>
    </React.Fragment>
  );
};

export default Header;
