import React, { useContext } from "react";
import { Container, Row, Col } from "react-bootstrap";
import renderHTML from "react-render-html";
import styled from "styled-components";
import Header from "./../../components/Header";

import { AppContext } from "./../../AppContext";

import { checkData } from "./../../hoc";
import IMAGEMUSEO from "./../../assets/bienvenida.jpg";
import { AppButton } from "./../../components/Button";

const ContainerW = styled(Container)`
  margin-top: 125px;
`;

const DescriptionHome = styled.p`
  font-size: 18px;
`;

const Home = () => {
  const { language } = useContext(AppContext);

  console.log(language)

  const toggleMenu = () => {
    const cb = document.querySelector(".bm-burger-button button");
    cb.click();
  };

  return (
    <React.Fragment>
      <Header />
      <ContainerW>
        <Row>
          <Col>
            <img className="w-100" src={IMAGEMUSEO} alt="" />
          </Col>
        </Row>

        <Row>
          <Col>
            <DescriptionHome 
              className="text-justify mt-5"
            >
              {language && language.descripcion
                ? renderHTML(language.descripcion)
                : ''
              }
            </DescriptionHome>
          </Col>
        </Row>
        
        <Row>
          <Col className="d-flex justify-content-center">
            <AppButton
              onClick={toggleMenu}
              style={{ marginTop: 30, marginBottom: 20 }}
            >
              {language && language.BOTON_INICIO
                ? language.BOTON_INICIO
                : "EMPEZAR"}
            </AppButton>
          </Col>
        </Row>
      </ContainerW>
    </React.Fragment>
  );
};

export default checkData(Home);
