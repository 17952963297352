import React, { useState, useMemo } from "react";
import ReactDOM from "react-dom";
import ReactGA from "react-ga";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import { AppContext } from "./AppContext";

const Main = () => {
  const [language, setLanguage] = useState(null);
  const [questionnaire, setQuestionnaire] = useState([]);
  const [doQuestionnaire, setDoQuestionnaire] = useState(true);
  const [filter, setFilter] = useState(null);
  const [languages, setLanguages] = useState([]);
  const [collections, setCollections] = useState([]);
  const [salas, setSalas] = useState([]);
  const [plantas, setPlantas] = useState([]);
  const [subMenus, setSubMenus] = useState([]);
  const [scrollId, setScrollId] = useState(null);

  const value = useMemo(
    () => ({
      languages,
      setLanguages,
      language,
      setLanguage,
      collections,
      setCollections,
      salas,
      setSalas,
      plantas,
      setPlantas,
      filter,
      setFilter,
      subMenus,
      setSubMenus,
      questionnaire,
      setQuestionnaire,
      doQuestionnaire,
      setDoQuestionnaire,
      scrollId,
      setScrollId,
    }),
    [
      languages,
      setLanguages,
      language,
      setLanguage,
      collections,
      setCollections,
      salas,
      setSalas,
      plantas,
      setPlantas,
      filter,
      setFilter,
      subMenus,
      setSubMenus,
      questionnaire,
      setQuestionnaire,
      doQuestionnaire,
      setDoQuestionnaire,
      scrollId,
      setScrollId,
    ]
  );

  const trackingId = "UA-192531903-1"; // Replace with your Google Analytics tracking ID
  ReactGA.initialize(trackingId);

  window.addEventListener("appinstalled", function (event) {
    ReactGA.event({
      category: "Application",
      action: "installed",
    });
  });

  return (
    <AppContext.Provider value={value}>
      <Router>
        <App />
      </Router>
    </AppContext.Provider>
  );
};

ReactDOM.render(<Main />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
