import React, { useState, useEffect } from "react";
import { Spinner, Row, Col } from "react-bootstrap";
import { getFile } from "../../services";
import Videojs from "./../VideoJs";
import "./../../../node_modules/video-react/dist/video-react.css";
import { auto } from "async";
// import video from "./../../assets/video.mp4";

const VideoPlayer = ({ url, name }) => {
  const videoJsOptions = {
    autoplay: false,
    playbackRates: [0.5, 1, 1.25, 1.5, 2],
    height: 300,
    controls: true,
    sources: [
      {
        src: url,
        type: "video/mp4",
      },
    ],
  };
  return <Videojs {...videoJsOptions} name={name} />;
  // return <Player playsInline poster={PREVIEW} src={url} />;
};

const Video = ({ videoId, name }) => {
  const [url, setUrl] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchVideo();
    // setLoading(true);
    // setTimeout(() => {
    //   setUrl(video);
    //   setLoading(false);
    // }, 2000);

    return () => {
      setUrl(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoId]);

  const fetchVideo = async () => {
    try {
      if (videoId) {
        setLoading(true);
        const { data } = await getFile(videoId);
        const url = URL.createObjectURL(data);
        setUrl(url);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <React.Fragment>
      
          {loading && <Spinner style={{ marginTop: 10 }} animation="grow" />}
          {!loading && <VideoPlayer url={url} />}
          {/* <VideoPlayer url={url} name={name} /> */}

    </React.Fragment>
  );
};

export default React.memo(Video, (prevProps, props) => {
  return prevProps.videoId === props.videoId;
});
