import styled from "styled-components";
import { Button } from "react-bootstrap";

export const AppButton = styled(Button)`
  color: #594e48;
  background-color: #c2bcb7;
  letter-spacing: 1px;
  font-size: 16px;
  width: 300px;
  padding: 10px 0px;
  border-radius: 25px;
  border: 1px solid #594e48;
  text-transform: uppercase;
  &:hover {
    outline: none;
    box-shadow: 0 0 3pt 2pt #d5d4d3;
    color: #c2bcb7;
    background-color: #594e48;
    border-color: #594e48 !important;
  }
  &:focus {
    outline: none;
    box-shadow: 0 0 3pt 2pt #d5d4d3;
    color: #c2bcb7;
    background-color: #594e48;
    border-color: #594e48 !important;
  }
`;
