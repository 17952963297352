import React, { useContext, useState } from "react";
import { Container, Row, Col, ProgressBar } from "react-bootstrap";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import ReactGA from "react-ga";
import ClockLoader from "react-spinners/ClockLoader"
import { AppContext } from "./../../AppContext";
import Header from "./../../components/Header";

import HOMEIMG from "./../../assets/bienvenida.jpg";
import { AppButton } from "./../../components/Button";

const ContainerWrapper = styled(Container)`
  margin-bottom: 30px;
`;

const Div = styled.div`
  margin-top: 130px;
  /* margin-bottom: 60px; */
  h1 {
    font-size: 40px;
    text-align: center;
    font-family: myriad-pro-light
  }
  & {
    @media screen and (max-width: 600px) {
      margin-top: 60px;
    }
  }
`;

const RowW = styled(Row)`
  padding: 0;
  margin: 0;
  /* background-color: #f2f1ee !important; */
`;

const IMG = styled.img`
  width: 100px;
  height: 100px;
`;

const Languages = () => {

  let history = useHistory();
  const { languages, setLanguage} = useContext(
    AppContext
  );

  const selectLanguage = (item) => {
    setLanguage(item);
    runGA(item);
    history.push("/");
  };

  const runGA = (item) => {
    ReactGA.event({
      category: "Language",
      action: `Selected -${item.nombre}`,
      value: parseInt(item.id),
    });
  };

  return (
    <React.Fragment>
      <Header />
      <Div>
        <h1>Audioguía</h1>
      </Div>
      
        <ContainerWrapper>
          <Row>
            <Col>
              <img className="w-100" src={HOMEIMG} alt="" />
            </Col>
          </Row>

          {languages.length < 1 && (
            <Row>
              <Col className="d-flex justify-content-center">
              <ClockLoader size={100} color={"#D58A95"} loading={true} />
              </Col>
            </Row>
          )}

          {languages.length > 0 &&
            languages.map((item, i) => {
              
              return (
                <React.Fragment>
                  {i === 0 && <div style={{ marginTop: 50 }} />}
                  <Row key={`lang-${item.id}`} className="mt-4">
                    <Col className="d-flex justify-content-center">
                      <AppButton
                        onClick={() => {
                          selectLanguage(item);
                        }}
                      >
                        <span>{item.nombre}</span>&nbsp;
                      </AppButton>
                    </Col>
                  </Row>
                </React.Fragment>
              );
            })}
        </ContainerWrapper>
      
    </React.Fragment>
  );
};

export default Languages;
