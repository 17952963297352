import React, { useEffect, useContext, useState } from "react";
import { useParams, Link, useLocation } from "react-router-dom";
import { Row, Col, Container, Card, Carousel } from "react-bootstrap";
import styled from "styled-components";

import ReactGA from "react-ga";

import "react-virtualized/styles.css";

import Header from "./../../components/Header";
import ImageLoader from "./../../components/ImageLoader";
import { checkData } from "./../../hoc";
import { AppContext } from "./../../AppContext";

import PLANTAPRESSSVG from "./../../assets/svg/planta_presionado.svg";
import VISTAGRIDSVG from "./../../assets/svg/vista_grid.svg";
import VISTALISTASVG from "./../../assets/svg/vista_lista.svg";

const ContainerWrapper = styled(Container)`
  margin-top: 130px;
  margin-bottom: 30px;
`;

const ItemWrapper =styled(Link)`
  padding:20px;
  border-top: 1px solid #403832 !important;
  border-left: 1px solid #403832 !important;
  border-right: 1px solid #403832 !important;
`;

const CardWrapper = styled(Card)`
  box-shadow: 8px 8px 5px #dfdedb !important;
  border-top: 1px solid #403832 !important;
  border-left: 1px solid #403832 !important;
  border-right: 1px solid #403832 !important;
  background-color: #c2bcb7 !important;
  font-size: 20px;
  margin: 0;
  padding: 15px;
  &:last-child{
    border-bottom: 1px solid #403832 !important;
  }
  &:focus,
  &:hover {
    box-shadow: 1px 10px 5px #dfdedb;
  }
`;
const CardTitle = styled(Card.Title)`
  /* font-family: Armata-Regular;
  color: #150f15;
  font-size: 1.5em; */

  font-family: EBGaramond-SemiBold;
  font-size: 25px;
  color: #403832;
`;
const CardText = styled(Card.Text)`
  font-family: Armata-Regular;
  margin-bottom: 0;
  letter-spacing: 1px;
  font-size: 20px;
  font-weight: bold;
`;
const CardText2 = styled(Card.Text)`
  font-family: Armata-Regular;
  margin-bottom: 0;
  letter-spacing: 2px;
  font-size: 16px;
  font-weight: 700;
`;
const SpanAutor = styled(Card.Text)`
  /* font-family: Armata-Regular; */
  margin-bottom: 0;
  /* font-size: 15px; */
  text-align: right;
  margin-right: 5px;

  font-family: EBGaramond-SemiBold;
  font-size: 25px;
  color: #403832;
`;

const CardTextTitle = styled(Card.Text)`
  font-family: Armata-Regular;
  margin-bottom: 0;
  letter-spacing: 1px;
  font-size: 18px;
  font-weight: bold;
  text-align: left;
  margin: 0 0 0 20px;
`;

const Vista = styled.div`
  display: flex;
  justify-content: space-evenly;
  span {
    font-family: Armata-Regular;
    margin-bottom: 0;
    letter-spacing: 2px;
    font-size: 16px;
    font-weight: 700;
  }
  img.list {
    height: 20px;
    cursor: pointer;
  }
  img.square {
    height: 20px;
    cursor: pointer;
  }
`;

const VistaGridDiv = styled.div`
  background-image: url("${VISTAGRIDSVG}");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  width: 21px;
  height: 21px;
  margin-left: 5px;
  cursor: pointer;
  /* &:focus,&:hover{
    background-image: url('${PLANTAPRESSSVG}');
  } */
`;



const Element = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};


const Collection = () => {
  let { collectionId } = useParams();
  const { collections, language, filter, scrollId } = useContext(AppContext);
  
  const [loading, setLoading] = useState(false);
  const [obras, setObras] = useState([]);
  const [view, setView] = useState("square");
  const [ra, setRa] = useState(Math.floor(Math.random() * 11));
  
  const [nList, setNlist] = useState(0)
  
  useEffect(() => {
    fetchCollection();

    setTimeout(() => {
      setRa(Math.floor(Math.random() * 11));
    }, 100);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionId, filter]);

  const fetchCollection = async () => {
    try {
      setLoading(true);
      if (collectionId) {
        if (filter) {
          filterCollections();
        } else {
          const data = collections.filter((item) => {
            return parseInt(item.id) === parseInt(collectionId);
          });
          if (data.length) {
            runGA(data[0]);
            setObras(sorts(data[0].obras));
          }
        }
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const sorts = (array) => {
    for (let i = 0; i < array.length - 1; i++) {
      for (let j = i + 1; j < array.length; j++) {
        if (parseInt(array[i].orden) > parseInt(array[j].orden)) {
          let aux = array[i];
          array[i] = array[j];
          array[j] = aux;
        }
      }
    }
    return array;
  };

  const runGA = (item) => {
    if (
      item.nombre &&
      language &&
      language.abreviatura &&
      item.nombre[language.abreviatura]
    ) {
      ReactGA.event({
        category: "Collection",
        action: `Visited -${item.nombre[language.abreviatura]}`,
        value: parseInt(collectionId),
      });
    }
  };

  const filterCollections = () => {
    setObras(sorts(filter.obras));
  };

  const changeView = (value) => {
    setView(value);
  };

  return (
    <React.Fragment>
      <Header />
      <ContainerWrapper key={ra} id="collections">
        {loading && (
          <Row style={{ marginTop: 260 }}>
            <Col className="d-flex justify-content-center"></Col>
          </Row>
        )}

        {!loading && (
          <React.Fragment>

            <Row className="sg-container d-flex justify-content-center">
              {obras.map((item, i) => {
                if (language && item.titulo[language.abreviatura]) {
                  return (
                    <ItemWrapper
                      className= "no-link w-100"
                      key={`obras${item.id}`}
                      to={`/collection/${collectionId}/artwork/${item.id}`}
                    >
                      <Col
                        xs={12}
                        key={`obras-${item.id}`}
                      >
                        <span className="h3">
                          { i + 1 }
                        </span>
                        <span className="ml-4 h5">
                          {item.titulo[language.abreviatura]}
                        </span>
                      </Col>
                    </ItemWrapper>
                  );
                }
              })}
            </Row>
            <Element />
          </React.Fragment>
        )}
      </ContainerWrapper>
    </React.Fragment>
  );
};

export default checkData(Collection);
